/* Tento soubor obsahuje override stylů flatpickr verze 4.6.11: https://github.com/flatpickr/flatpickr/tree/v4.6.11 */

/* Calendar container */
.flatpickr-calendar {
  width: auto;
  padding: 0;
  animation: none;
  background: transparent;
  border: 0;
  border-radius: var(--ok-radii-md);
  box-shadow: var(--ok-shadows-card);
  font-size: var(--ok-fontSizes-md);
  line-height: var(--ok-lineHeights-base);
  overflow: hidden;
  text-align: center;
}

.flatpickr-calendar.arrowTop::after,
.flatpickr-calendar.arrowTop::before {
  border-bottom-color: var(--ok-colors-white);
}

/* Days */
.flatpickr-days {
  border-left: none;
  border-right: none;
}

/* Day */
.flatpickr-day {
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;

  display: grid;
  justify-content: center;
  width: auto;
  height: auto;
  position: relative;
  max-height: none;
  margin: 0;
  padding: var(--ok-space-2);
  background: var(--ok-colors-white);
  border: none;
  border-radius: var(--ok-radii-sm);
  color: var(--ok-colors-primary-800);
  cursor: pointer;
  font-weight: var(--ok-fontWeights-normal);
  line-height: var(--ok-lineHeights-base);
  vertical-align: middle;
  text-align: center;

  &:hover {
    background-color: var(--ok-colors-gray-200);
  }
}

.flatpickr-day.today {
  background-color: var(--ok-colors-secondary-200);
  border-color: transparent;

  &:hover {
    background-color: var(--ok-colors-secondary-500);
  }
}

span.flatpickr-day.selected {
  background-color: var(--ok-colors-primary-800);
  border-color: transparent;
  color: var(--ok-colors-white);
  font-weight: var(--ok-fontWeight-semibold);

  &:hover {
    background-color: var(--ok-colors-secondary-500);
  }
}

/* Months */
.flatpickr-months .flatpickr-month {
  background: var(--ok-colors-white);
}

.flatpickr-current-month {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: var(--ok-fontSizes-md);
  font-weight: var(--ok-fontWeights-normal);
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: var(--ok-radii-base);
  color: var(--ok-colors-primary-800);
  font-weight: var(--ok-fontWeights-normal);
}

.numInputWrapper {
  border-radius: var(--ok-radii-base);
}

.flatpickr-current-month input.cur-year {
  color: var(--ok-colors-primary-800);
  font-weight: var(--ok-fontWeights-normal);
}

/* Weekdays */
.flatpickr-weekdays {
  background: transparent;
}

span.flatpickr-weekday {
  display: block;
  flex: 1;
  margin: 0;
  background: transparent;
  color: var(--ok-colors-gray-600);
  cursor: default;
  font-size: var(--ok-fontSizes-sm);
  font-weight: var(--ok-fontWeight-semibold);
  line-height: 1;
  text-align: center;
}

/* Arrows */
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--ok-colors-primary-800);
}
